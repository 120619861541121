@import '../../Components/Styles/Text';
@import '../../Components/Styles/Colors';

.home_text {
  color: white;
}

.home_desktop {
  height: 100vh;
}
.home_mobile {
  height: 100vh;
}
.home_parent_sectionheading {
  position: absolute;
  margin-top: -200px;
}
.whitekeys_container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: 568px;
  display: grid;
  place-items: center;
  text-align: center;
}
.whitekeys_container_contents {
  display: grid;
  grid-template-columns: repeat(52, 1fr);
  @media screen and (max-width: 1366px) {
    grid-template-columns: repeat(7, 1fr);
  }
}
.whitekeys_container_key {
  @media screen and (min-width:2561px) {
    outline: 2px solid $backgroundcolor3;
    background: $backgroundcolor1;
    height: 288px;
    width: 42px;
  }
  @media screen and (max-width:2560px) {
    outline: 1px solid $backgroundcolor3;
    background: $backgroundcolor1;
    height: 144px;
    width: 21px;
  }
}
.blackkeys_container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: 568px;
  display: grid;
  place-items: center;
  text-align: center;
}
.blackkeys_container_contents {
  display: grid;
  grid-template-columns: repeat(52, 1fr);
  @media screen and (max-width: 1366px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media screen and (min-width:2561px) {
    margin-left: -44px;
  }
  @media screen and (max-width:2560px) {
    margin-left: -22px;
  }
}
.blackkeys_container_keyAs,
.blackkeys_container_keyCs,
.blackkeys_container_keyDs,
.blackkeys_container_keyFs,
.blackkeys_container_keyGs,
.blackkeys_container_keyt {
  @media screen and (min-width:2561px) {
    background: $backgroundcolor3;
    height: 184px;
    width: 22px;
    margin-top: -104px;
  }
  @media screen and (max-width:2560px) {
    background: $backgroundcolor3;
    height: 92px;
    width: 11px;
    margin-top: -52px;
  }
}
.blackkeys_container_keyt {
  outline: transparent;
  background-color: transparent;
}
.blackkeys_container_keyAs,
.blackkeys_container_keyCs,
.blackkeys_container_keyDs,
.blackkeys_container_keyFs,
.blackkeys_container_keyGs {
  @media screen and (min-width:2561px) {
    margin: -104px 10px 0 10px;
  }
  @media screen and (max-width:2560px) {
    margin: -52px 5px 0 5px;
  }
}
@media screen and (min-width: 1367px) {
  .home_mobile {
    display: none;
  }
}
@media screen and (max-width: 1366px) {
  .home_desktop {
    display: none;
  }
}
