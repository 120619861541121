/*
0-400 resolution (mobile),
401-768 resolution (mobile2),
769-1366 resolution (desktop),
1367-1920 resolution (desktop2),
1921-2560 resolution(desktop3),
2561+ resolution (desktop4)
*/

$mobile-fontsize1: 10px;
$mobile-fontsize2: 15px;
$mobile-fontsize3: 25px;
$mobile-fontsize4: 40px;
$mobile-fontsize5: 35px;

$mobile2-fontsize1: 10px;
$mobile2-fontsize2: 15px;
$mobile2-fontsize3: 30px;
$mobile2-fontsize4: 40px;
$mobile2-fontsize5: 45px;

$desktop-fontsize1: 12px;
$desktop-fontsize2: 15px;
$desktop-fontsize3: 40px;
$desktop-fontsize4: 50px;
$desktop-fontsize5: 70px;

$desktop2-fontsize1: 14px;
$desktop2-fontsize2: 18px;
$desktop2-fontsize3: 50px;
$desktop2-fontsize4: 60px;
$desktop2-fontsize5: 90px;

$desktop3-fontsize1: 21px;
$desktop3-fontsize2: 27px;
$desktop3-fontsize3: 75px;
$desktop3-fontsize4: 90px;
$desktop3-fontsize5: 135px;

$desktop4-fontsize1: 28px;
$desktop4-fontsize2: 36px;
$desktop4-fontsize3: 100px;
$desktop4-fontsize4: 120px;
$desktop4-fontsize5: 180px;
