@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import './Components/Styles/Text';
@import './Components/Styles/Colors';

body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
  font-family: "Montserrat", "Times New Roman";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  padding: 0;
}
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.fontsize1,
.fontsize2,
.fontsize3,
.fontsize4,
.fontsize5 {
  color: $textcolor1;
}

@media screen and (min-width: 2561px) {
  .fontsize1 {
    font-size: $desktop4-fontsize1;
  }
  .fontsize2 {
    font-size: $desktop4-fontsize2;
  }
  .fontsize3 {
    font-size: $desktop4-fontsize3;
  }
  .fontsize4 {
    font-size: $desktop4-fontsize4;
  }
  .fontsize5 {
    font-size: $desktop4-fontsize5;
  }
}
@media screen and (max-width: 2560px) {
  .fontsize1 {
    font-size: $desktop3-fontsize1;
  }
  .fontsize2 {
    font-size: $desktop3-fontsize2;
  }
  .fontsize3 {
    font-size: $desktop3-fontsize3;
  }
  .fontsize4 {
    font-size: $desktop3-fontsize4;
  }
  .fontsize5 {
    font-size: $desktop3-fontsize5;
  }
}
@media screen and (max-width: 1920px) {
  .fontsize1 {
    font-size: $desktop2-fontsize1;
  }
  .fontsize2 {
    font-size: $desktop2-fontsize2;
  }
  .fontsize3 {
    font-size: $desktop2-fontsize3;
  }
  .fontsize4 {
    font-size: $desktop2-fontsize4;
  }
  .fontsize5 {
    font-size: $desktop2-fontsize5;
  }
}
@media screen and (max-width: 1366px) {
  .fontsize1 {
    font-size: $desktop-fontsize1;
  }
  .fontsize2 {
    font-size: $desktop-fontsize2;
  }
  .fontsize3 {
    font-size: $desktop-fontsize3;
  }
  .fontsize4 {
    font-size: $desktop-fontsize4;
  }
  .fontsize5 {
    font-size: $desktop-fontsize5;
  }
}
@media screen and (max-width: 768px) {
  .fontsize1 {
    font-size: $mobile2-fontsize1;
  }
  .fontsize2 {
    font-size: $mobile2-fontsize2;
  }
  .fontsize3 {
    font-size: $mobile2-fontsize3;
  }
  .fontsize4 {
    font-size: $mobile2-fontsize4;
  }
  .fontsize5 {
    font-size: $mobile-fontsize5;
  }
}
@media screen and (max-width: 400px) {
  .fontsize1 {
    font-size: $mobile-fontsize1;
  }
  .fontsize2 {
    font-size: $mobile-fontsize2;
  }
  .fontsize3 {
    font-size: $mobile-fontsize3;
  }
  .fontsize4 {
    font-size: $mobile-fontsize4;
  }
  .fontsize5 {
    font-size: $mobile-fontsize5;
  }
}




/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
