$backgroundcolor1: white;
$backgroundcolor2: #F5F5F5;
/*
$backgroundcolor3: #F0F0F0;
*/
$backgroundcolor3: black;

$textcolor1: #000000;
$textcolor2: #646464;
$textcolor3: #C86400;
